import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import me from '../images/about1.jpg'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO
        title="About | JT"
        desc=" Hello, my name is JT Pham. I'm a fashion and portait photographer based in Los Angeles."
      />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hello, my name is JT Pham.</h1>

        <div style={{ float: 'left', width: '50%'}}>
          <img style={{ width: '90%' }} alt="jt" src={me} />
        </div>
        <div style={{ float: 'right', width: '50%' }}>
          <p>
            I'm a fashion and portait photographer based in Los Angeles. What I particularly love about photography is
            the ability to capture a moment in time, the beauty of life, and the stories that our eyes can tell the
            world. Beyond that, I am so incredibly grateful to have the opportunities to work with new people and to
            hear their stories.
          </p>
          <p>
            Thanks for coming to check out my work, and if you'd like to trust your time with me, please contact me and
            we will make it happen together.
          </p>
        </div>
      </AnimatedBox>
    </Layout>
  )
}

export default About
